import {
  EnumExportTransactionsType,
  ExportInternalAccountTransactionsFormData,
} from 'modules/bank/hooks/transaction/useExportInternalAccountTransactionsForm'
import { VerticalProgressStep } from 'modules/common/components/VerticalProgressStepper/VerticalProgressStep/VerticalProgressStep'
import { VerticalProgressStepper } from 'modules/common/components/VerticalProgressStepper/VerticalProgressStepper'
import { IconEmail } from 'modules/common/components/_icons/icons'
import { RadioButton } from 'modules/common/components/_ui/FormControl/RadioButton/RadioButton'
import { TextField } from 'modules/common/components/_ui/FormControl/TextField/TextField'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { useVerticalProgressStepStatus } from 'modules/common/hooks/useVerticalProgressStepStatus'
import { Controller, useFormContext } from 'react-hook-form'
import {
  TransactionMonthOption,
  TransactionMonthSelect,
} from '../TransactionMonthSelect/TransactionMonthSelect'

interface Props {
  monthOptions: TransactionMonthOption[]
  validatedStepNumber: number
}

export const ExportInternalAccountTransactionsFormContent: React.FC<Props> = ({
  monthOptions,
  validatedStepNumber,
}) => {
  const { t } = useTranslation('bank-components')

  const {
    register,
    control,
    formState: { errors },
    watch,
  } = useFormContext<ExportInternalAccountTransactionsFormData>()

  const exportType = watch('exportType')

  const { registerStepStatus } = useVerticalProgressStepStatus(
    validatedStepNumber
  )

  return (
    <div className="flex w-96 flex-col">
      <VerticalProgressStepper>
        <VerticalProgressStep
          title={t('exportInternalAccountTransactionsForm.monthTitle')}
          {...registerStepStatus(0)}
        >
          <Controller
            name="month"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <TransactionMonthSelect
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                options={monthOptions}
                className="mb-3"
              />
            )}
          />
        </VerticalProgressStep>
        <VerticalProgressStep
          title={t('exportInternalAccountTransactionsForm.exportTypeTitle')}
          {...registerStepStatus(1)}
        >
          <RadioButton
            label={t(
              'exportInternalAccountTransactionsForm.accountStatementLabel'
            )}
            value={EnumExportTransactionsType.SIMPLE}
            className="mt-4"
            labelClassName="font-bold text-info-600"
            {...register('exportType')}
          />
          <p className="mt-2">
            {t('exportInternalAccountTransactionsForm.accountStatementDesc')}
          </p>
          <RadioButton
            label={t(
              'exportInternalAccountTransactionsForm.accountingExportLabel'
            )}
            value={EnumExportTransactionsType.ADVANCED}
            className="mt-6"
            labelClassName="font-bold text-info-600"
            {...register('exportType')}
          />
          <p className="mt-2">
            {t('exportInternalAccountTransactionsForm.accountingExportDesc')}
          </p>
        </VerticalProgressStep>
        {exportType === EnumExportTransactionsType.ADVANCED && (
          <VerticalProgressStep
            title={t('exportInternalAccountTransactionsForm.email.title')}
            {...registerStepStatus(2)}
          >
            <TextField
              {...register('email')}
              label={t('exportInternalAccountTransactionsForm.email.label')}
              helperText={t(
                'exportInternalAccountTransactionsForm.email.helperText'
              )}
              leftDecorator={<IconEmail />}
              control={control}
              error={errors.email}
            />
          </VerticalProgressStep>
        )}
      </VerticalProgressStepper>
    </div>
  )
}
