import clsx from 'clsx'
import { useAuthenticatedUserQuery } from 'modules/auth/queries/useAuthenticatedUser.query'
import { Illustration } from 'modules/common/components/Illustrations/Illustration'
import { useTranslation } from 'modules/common/hooks/useTranslation'

interface Props {
  withIllustration?: boolean
  className?: string
}

export const AccountBlockedInvest = ({
  withIllustration = false,
  className,
}: Props) => {
  const { data: user } = useAuthenticatedUserQuery()
  const { t } = useTranslation('bank-components')

  return (
    <div
      className={clsx(
        'flex flex-1 flex-col items-center justify-center gap-6',
        className
      )}
    >
      {withIllustration && <Illustration name="phone-cut-error" size="l" />}
      <h3 className="w-80 text-center font-default text-2xl font-bold">
        {t('accountBlockedInvest.accountBlockedInvestTitle')}
      </h3>
      <p className="w-96 text-center text-info-400">
        {t('accountBlockedInvest.accountBlockedInvestDescriptionPart1')}
      </p>
      <p className="font-bold text-info-400">{user?.email}</p>
      <p className="w-96 text-center text-info-400">
        {t('accountBlockedInvest.accountBlockedInvestDescriptionPart2')}
      </p>
    </div>
  )
}
