import Axios from 'axios'
import { useInfiniteQueryWithAuth } from 'modules/auth/hooks/useInfiniteQueryWithAuth'
import { PaginatedList } from 'modules/common/types/utils'
import querystring from 'query-string'
import { UseInfiniteQueryOptions } from 'react-query'
import { Transaction } from '../types'
import { EnumBankQueryKeys } from './keys'

interface FetchAccountTransactionsArgs {
  resultsPerPage?: number
  dateFrom?: string
  pageToken?: string
  accountId: string
  accessToken: string
}

const fetchAccountTransactions = async ({
  accountId,
  accessToken,
  resultsPerPage = 20,
  dateFrom = '2015-02-02T00:00:00.000Z',
  pageToken,
}: FetchAccountTransactionsArgs) => {
  const apiUrl = querystring.stringifyUrl({
    url: `${process.env.NEXT_PUBLIC_API_URL}/v1/bank/accounts/${accountId}/transactions`,
    query: {
      resultsPerPage,
      dateFrom,
      pageToken,
    },
  })

  const { data } = await Axios.get<PaginatedList<Transaction>>(apiUrl, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
  return data
}

export const useAccountTransactionsQuery = (
  accountId: string,
  queryOptions?: UseInfiniteQueryOptions<PaginatedList<Transaction>, Error>
) => {
  const { data: infiniteData, ...rest } = useInfiniteQueryWithAuth<
    PaginatedList<Transaction>,
    Error
  >(
    [EnumBankQueryKeys.TRANSACTIONS_LIST, accountId],
    async ({ pageParam }, accessToken) => {
      return fetchAccountTransactions({
        accountId,
        accessToken,
        ...pageParam,
      })
    },
    {
      getNextPageParam: ({ nextPageToken }) => {
        if (nextPageToken) {
          return { pageToken: nextPageToken }
        }
        return undefined
      },
      ...queryOptions,
    }
  )

  const data = infiniteData?.pages.reduce<Transaction[]>(
    (allData, page) => [...allData, ...page.data],
    []
  )

  return {
    data,
    ...rest,
  }
}
