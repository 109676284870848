import { getFileUrl } from 'modules/common/lib/helpers/getFileUrl/getFileUrl'
import { MimeTypes } from '_constants'

interface Args {
  blob: string | Blob
  fileName: string
  format?: MimeTypes
}

export const downloadFile = ({
  blob,
  fileName,
  format = MimeTypes.PDF,
}: Args) => {
  const url = getFileUrl(blob, format)

  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  // Append to html page
  document.body.appendChild(link)
  // Force download
  link.click()
  // Clean up and remove the link
  link.parentNode?.removeChild(link)
}
