import axios from 'axios'
import { useFreshToken } from 'modules/auth/hooks/useFreshToken'
import querystring from 'query-string'
import { MimeTypes } from '_constants'
import { formatExportTransactionsDate } from 'modules/bank/utils/format/transactions'

interface Props {
  accountId: string
}

export const useFetchMonthTransactions = ({ accountId }: Props) => {
  const getFreshToken = useFreshToken()

  const getMonthTransactionsExport = async (
    dateFrom: string,
    format = MimeTypes.PDF
  ) => {
    const accessToken = await getFreshToken()

    if (!accessToken) {
      throw new Error()
    }

    const apiUrl = querystring.stringifyUrl(
      {
        url: `${process.env.NEXT_PUBLIC_API_URL}/v1/bank/accounts/${accountId}/transactions/statement`,
        query: {
          dateFrom: formatExportTransactionsDate(dateFrom),
        },
      },
      { encode: false }
    )

    const { data } = await axios.get<Blob>(apiUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: format,
      },
      ...(format === MimeTypes.PDF && { responseType: 'blob' }),
    })

    return data
  }

  return { getMonthTransactionsExport }
}
