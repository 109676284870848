import { useMutationWithAuth } from 'modules/auth/hooks/useMutationWithAuth'
import axios, { AxiosError } from 'axios'
import { UseMutationOptions } from 'react-query'
import { Account, PostAdvancedTransactionsExportPayload } from '../types'

interface MutationVariables {
  payload: PostAdvancedTransactionsExportPayload
  account: Account
  accessToken: string
}

const postAdvancedTransactionsExport = async ({
  payload,
  account,
  accessToken,
}: MutationVariables) => {
  const { data } = await axios.post<Record<string, never>>(
    `${process.env.NEXT_PUBLIC_API_URL}/v1/bservices/${account.corporateId}/accounts/${account.id}/transactions/export/advanced`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )
  return data
}

export const useExportTransactionsAdvancedMutation = (
  account: Account,
  mutationOptions?: UseMutationOptions<
    Record<string, never>,
    AxiosError,
    PostAdvancedTransactionsExportPayload
  >
) => {
  return useMutationWithAuth<
    Record<string, never>,
    AxiosError,
    PostAdvancedTransactionsExportPayload
  >(
    async (payload, accessToken) => {
      return postAdvancedTransactionsExport({
        account,
        payload,
        accessToken,
      })
    },
    {
      ...mutationOptions,
    }
  )
}
