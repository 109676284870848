import {
  IllustrationName,
  IllustrationSize,
} from 'modules/common/types/illustrations'
import Image, { ImageProps } from 'next/image'

interface Props {
  size: IllustrationSize
  name: IllustrationName
  imageProps?: ImageProps
}

const SIZES: Record<IllustrationSize, string> = {
  xl: '170',
  l: '136',
  m: '112',
  s: '88',
}

export const Illustration = ({ size, name, imageProps }: Props) => {
  const imagePath = `/assets/img/illustrations/${name}.png`

  return (
    <Image
      {...imageProps}
      src={imagePath}
      width={SIZES[size]}
      height={SIZES[size]}
    />
  )
}
