import { IconCalendar } from 'modules/common/components/_icons/icons'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import ReactSelect, {
  components,
  ControlProps,
  Props as ReactSelectProps,
} from 'react-select'

export interface TransactionMonthOption {
  value: string
  label: string
}

const CustomControl: React.FC<ControlProps<TransactionMonthOption, false>> = (
  props
) => {
  return (
    <components.Control {...props}>
      {props.selectProps.value && <IconCalendar className="mr-2" />}
      {props.children}
    </components.Control>
  )
}

const CustomPlaceholder: React.FC<{
  title: string
}> = ({ title }) => {
  return (
    <div className="flex items-center text-info-600">
      <IconCalendar className="mr-2" />
      <span>{title}</span>
    </div>
  )
}

export const TransactionMonthSelect: React.FC<
  ReactSelectProps<TransactionMonthOption, false>
> = ({ className, ...selectProps }) => {
  const { t } = useTranslation('bank-components')

  return (
    <ReactSelect
      {...selectProps}
      className={className}
      classNamePrefix="blnk-react-select"
      inputId="transactionMonth"
      placeholder={
        <CustomPlaceholder title={t('transactionMonthSelect.placeholder')} />
      }
      components={{
        Control: CustomControl,
      }}
      isSearchable={false}
    />
  )
}
