import {
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from 'react-query'
import { QueryFunction } from '../types/queries'
import { useFreshToken } from './useFreshToken'

export const useInfiniteQueryWithAuth = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  queryOptions?: Omit<
    UseInfiniteQueryOptions<
      TQueryFnData,
      TError,
      TData,
      TQueryFnData,
      TQueryKey
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const getFreshToken = useFreshToken()

  return useInfiniteQuery<TQueryFnData, TError, TData, TQueryKey>(
    queryKey,
    async (context) => {
      const accessToken = await getFreshToken()

      if (!accessToken) {
        throw new Error(`useQueryWithAuth[${queryKey}]: Missing Access Token`)
      }

      return queryFn(context, accessToken)
    },
    queryOptions
  )
}
