import clsx from 'clsx'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { Trans } from 'next-i18next'
import Image from 'next/image'
import React from 'react'
import { IconReload } from '../_icons/icons'
import { Button } from '../_ui/Button/Button'

export interface ErrorReloadSmallProps {
  onReload: () => void
  title?: string
  description?: string
  buttonLabel?: string
  className?: string
  withIcon?: boolean
  withTitle?: boolean
}

export const ErrorReloadSmall: React.FC<ErrorReloadSmallProps> = ({
  onReload,
  title,
  description,
  buttonLabel,
  className,
  withIcon = true,
  withTitle = true,
}) => {
  const { t } = useTranslation('common')

  return (
    <div
      className={clsx(
        'flex h-full w-full flex-col items-center justify-center space-y-6',
        className
      )}
    >
      {withIcon && (
        <Image src="/assets/img/red-cross.png" width="52" height="52" />
      )}
      {withTitle && (
        <h3 className="text-center font-default text-2xl font-bold">
          {title || t('errors.reloadContent.title')}
        </h3>
      )}
      <Trans parent="p" className="text-center text-info-400">
        {description || t('errors.reloadContent.bodySmall')}
      </Trans>
      <Button
        color="active"
        variant="link"
        onClick={onReload}
        className="flex items-center py-2"
        type="button"
        icon={<IconReload />}
      >
        {buttonLabel || t('errors.reloadContent.cta.retry')}
      </Button>
    </div>
  )
}
