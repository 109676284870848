import { ExportExternalAccountTransactionsFormContent } from 'modules/bank/components/transaction/ExportExternalAccountTransactionsFormContent/ExportExternalAccountTransactionsFormContent'
import { Account } from 'modules/bank/types'
import { formatExportTransactionsDate } from 'modules/bank/utils/format/transactions'
import { getMonthOptions } from 'modules/bank/utils/transform/months'
import { useFormModal } from 'modules/common/modals/useFormModal/useFormModal'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { useMemo } from 'react'
import { FormProvider } from 'react-hook-form'
import { useExportMonthTransactionsAdvancedFlow } from 'modules/bank/flows/transaction/useExportMonthTransactionsAdvancedFlow'
import {
  ExportExternalAccountTransactionsFormData,
  useExportExternalAccountTransactionsForm,
} from 'modules/bank/hooks/transaction/useExportExternalAccountTransactionsForm'
import { useExportExternalAccountTransactionsFormSetup } from 'modules/bank/hooks/transaction/useExportExternalAccountTransactionsFormSetup'

export const useExportExternalAccountTransactionsModal = (account: Account) => {
  const { t, i18n } = useTranslation(['bank-components', 'common'])

  const { firstTransactionDate, createdAt } = account

  // Compute month list from firstTransactionDate if exist
  // Else, compute 3 month before createdAt
  const monthOptions = useMemo(
    () =>
      firstTransactionDate
        ? getMonthOptions(firstTransactionDate)
        : getMonthOptions(createdAt, 3),
    [firstTransactionDate, createdAt]
  )

  const form = useExportExternalAccountTransactionsForm({
    defaultMonth: monthOptions[0],
  })
  useExportExternalAccountTransactionsFormSetup(form)

  const {
    buildAdvancedMonthTransactionExport,
  } = useExportMonthTransactionsAdvancedFlow(account)

  const onSubmit = ({
    email,
    month,
  }: ExportExternalAccountTransactionsFormData) =>
    buildAdvancedMonthTransactionExport({
      email,
      date: formatExportTransactionsDate(month.value),
    })

  return useFormModal({
    form,
    content: (
      <FormProvider {...form}>
        <ExportExternalAccountTransactionsFormContent
          monthOptions={monthOptions}
        />
      </FormProvider>
    ),
    headerTitle: t('exportExternalAccountTransactionsModal.h1'),
    cancelButtonText: t('common:cancel'),
    submitButtonText: t('exportExternalAccountTransactionsModal.submitButton'),
    i18n,
    hiddenOnError: true,
    onSubmit,
  })
}
