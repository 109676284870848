import { Amount } from 'modules/common/components/Amount/Amount'
import { Skeleton } from 'modules/common/components/_ui/Skeletons/Skeleton/Skeleton'

interface Props {
  amount: number
}

export const BalanceAmount = ({ amount }: Props) => {
  return (
    <Amount
      amount={amount}
      className="shrink-0 font-headings font-bold text-info-600"
      integerClassName="text-2xl"
      decimalClassName="text-xl"
    />
  )
}

export const BalanceAmountSkeleton = () => {
  return <Skeleton className="block h-8 w-24" />
}
