import { Account } from 'modules/bank/types'
import { formatExportTransactionsDate } from 'modules/bank/utils/format/transactions'
import { getMonthOptions } from 'modules/bank/utils/transform/months'
import { useFormModal } from 'modules/common/modals/useFormModal/useFormModal'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { useMemo } from 'react'
import { FormProvider } from 'react-hook-form'
import { useExportMonthTransactionsSimpleFlow } from 'modules/bank/flows/transaction/useExportMonthTransactionsSimpleFlow'
import { useExportMonthTransactionsAdvancedFlow } from 'modules/bank/flows/transaction/useExportMonthTransactionsAdvancedFlow'
import { ExportInternalAccountTransactionsFormContent } from 'modules/bank/components/transaction/ExportInternalAccountTransactionsFormContent/ExportInternalAccountTransactionsFormContent'
import {
  EnumExportTransactionsType,
  ExportInternalAccountTransactionsFormData,
  useExportInternalAccountTransactionsForm,
} from 'modules/bank/hooks/transaction/useExportInternalAccountTransactionsForm'
import { useExportInternalAccountTransactionsFormState } from 'modules/bank/hooks/transaction/useExportInternalAccountTransactionsFormState'
import { useExportInternalAccountTransactionsFormSetup } from 'modules/bank/hooks/transaction/useExportInternalAccountTransactionsFormSetup'

export const useExportInternalAccountTransactionsModal = (account: Account) => {
  const { t, i18n } = useTranslation(['bank-components', 'common'])

  const monthOptions = useMemo(() => getMonthOptions(account.createdAt), [
    account.createdAt,
  ])

  const form = useExportInternalAccountTransactionsForm({
    defaultMonth: monthOptions[0],
  })
  const { validatedStepNumber } = useExportInternalAccountTransactionsFormState(
    form.formState.errors
  )
  useExportInternalAccountTransactionsFormSetup(form)

  const exportType = form.watch('exportType')

  const { downloadMonthTransactions } = useExportMonthTransactionsSimpleFlow(
    account.id
  )
  const {
    buildAdvancedMonthTransactionExport,
  } = useExportMonthTransactionsAdvancedFlow(account)

  const onSubmit = ({
    email,
    exportType,
    month,
  }: ExportInternalAccountTransactionsFormData) => {
    if (exportType === EnumExportTransactionsType.SIMPLE) {
      return downloadMonthTransactions(month.value)
    } else {
      return buildAdvancedMonthTransactionExport({
        email,
        date: formatExportTransactionsDate(month.value),
      })
    }
  }

  return useFormModal({
    form,
    content: (
      <FormProvider {...form}>
        <ExportInternalAccountTransactionsFormContent
          monthOptions={monthOptions}
          validatedStepNumber={validatedStepNumber}
        />
      </FormProvider>
    ),
    headerTitle: t('exportInternalAccountTransactionsModal.h1'),
    cancelButtonText: t('common:cancel'),
    submitButtonText: t('exportInternalAccountTransactionsModal.submitButton', {
      context: exportType,
    }),
    i18n,
    hiddenOnError: true,
    onSubmit,
  })
}
