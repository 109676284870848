import { useMemo } from 'react'
import { FormState } from 'react-hook-form'
import { ExportInternalAccountTransactionsFormData } from './useExportInternalAccountTransactionsForm'

export const useExportInternalAccountTransactionsFormState = (
  formErrors: FormState<ExportInternalAccountTransactionsFormData>['errors']
) => {
  const {
    month: monthError,
    exportType: exportTypeError,
    email: emailError,
  } = formErrors

  // Compute validated step number according to field errors
  const validatedStepNumber = useMemo(() => {
    if (monthError) {
      return 0
    } else if (exportTypeError) {
      return 1
    } else if (emailError) {
      return 2
    }
    return 3
  }, [monthError, exportTypeError, emailError])

  return { validatedStepNumber }
}
