import dayjs from 'dayjs'
import { useExportTransactionsSimpleFeedbackAlerts } from 'modules/bank/hooks/transaction/useExportTransactionsSimpleFeedbackAlerts'
import { useFetchMonthTransactions } from 'modules/bank/lib/api/useFetchMonthTransactions'
import { BANK_TRACKING_EVENTS } from 'modules/bank/lib/tracking/events'
import { useTrackEvent } from 'modules/common/hooks/useTrackEvent'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { downloadFile } from 'modules/common/lib/helpers/downloadFile/downloadFile'

export const useExportMonthTransactionsSimpleFlow = (accountId: string) => {
  const { t } = useTranslation('bank-components')

  const trackEvent = useTrackEvent()

  const {
    showExportTransactionsSuccessAlert,
    showExportTransactionsErrorAlert,
  } = useExportTransactionsSimpleFeedbackAlerts()

  const { getMonthTransactionsExport } = useFetchMonthTransactions({
    accountId,
  })

  const getMonthTransactionsPDF = async (date: string) => {
    const blob = await getMonthTransactionsExport(date)
    return {
      blob,
      fileName: t(
        'exportInternalAccountTransactionsModal.transactionsFileTitle',
        {
          date: dayjs(date).toDate(),
        }
      ),
    }
  }

  const downloadMonthTransactions = async (date: string) => {
    try {
      const { blob, fileName } = await getMonthTransactionsPDF(date)
      downloadFile({
        blob,
        fileName,
      })
      trackEvent({
        eventType: BANK_TRACKING_EVENTS.SIMPLE_TRANSACTIONS_EXPORT,
      })
      showExportTransactionsSuccessAlert()
    } catch (ex) {
      showExportTransactionsErrorAlert()
    }
  }

  return {
    downloadMonthTransactions,
  }
}
