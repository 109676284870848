import { EnumExportTransactionsType } from 'modules/bank/hooks/transaction/useExportInternalAccountTransactionsForm'
import { BANK_TRACKING_EVENTS } from 'modules/bank/lib/tracking/events'
import { useExportTransactionsAdvancedMutation } from 'modules/bank/mutations/useExportTransactionsAdvanced.mutation'
import { Account } from 'modules/bank/types'
import { useFeedbackAlertCallbacks } from 'modules/common/hooks/useFeedbackAlertCallbacks'
import { useTrackEvent } from 'modules/common/hooks/useTrackEvent'
import { useTranslation } from 'modules/common/hooks/useTranslation'

export const useExportMonthTransactionsAdvancedFlow = (account: Account) => {
  const { t } = useTranslation('bank-components')
  const trackEvent = useTrackEvent()

  const feedbackAlertCallbacks = useFeedbackAlertCallbacks({
    successDescription: t('exportTransactionsFeedback.success', {
      context: EnumExportTransactionsType.ADVANCED,
    }),
    errorDescription: t('exportTransactionsFeedback.error', {
      context: EnumExportTransactionsType.ADVANCED,
    }),
  })

  const {
    mutateAsync: buildAdvancedMonthTransactionExport,
  } = useExportTransactionsAdvancedMutation(account, {
    onMutate: () =>
      trackEvent({
        eventType: BANK_TRACKING_EVENTS.ADVANCED_TRANSACTIONS_EXPORT,
        eventProperties: { source: account.source },
      }),
    ...feedbackAlertCallbacks,
  })

  return {
    buildAdvancedMonthTransactionExport,
  }
}
